import { GetStaticPaths, NextPage } from 'next';
import Script from 'next/script';

import { ContentBlock } from '../../../components/elements/content';
import { Img } from '../../../components/elements/img';
import { Layout } from '../../../components/template/layout';
import { getCountryStaticPaths } from '../../../services/paths';
import { buildResource } from '../../../services/resources';
import { Country, CountryStaticProps, Resource } from '../../../services/types';
import { s3 } from '../../../utils/env';
import { GetStaticProps } from '../../../utils/nextTypes';
import { FourOhFour } from '../../404';

type PageProps = {
  title: string;
  country: Country;
  resource: Resource;
};

const MobileApp: NextPage<PageProps> = ({ title, country, resource }) => {
  if (resource.id === 0) {
    return <FourOhFour />;
  }
  return (
    <Layout
      title={title}
      country={country}
      description={resource?.description}
      sharingImage={resource?.image}
      showNotification={false}>
      <Script src="https://code.jquery.com/jquery-3.6.0.min.js" id="jquery" />

      <ContentBlock>
        <div className="grid grid-col-1 md:grid-cols-2 mb-12 xl:mb-0">
          <div className="md:order-2">
            <Img
              alt="Topgolf Mobile App Screenshot"
              src="uploads/promo/tg-app-screen-transparent-2.png?resize.width=800"
              className="w-full object-contain"
              width={800}
              height={800}>
              <source
                srcSet={s3 + 'uploads/promo/tg-app-screen-transparent-2.png?resize.width=1280'}
                media="(min-width: 768px)"
              />
            </Img>
          </div>
          <div className="md:order-1">
            <h1 className="font-shield-black text-6xl md:text-3 lg:text-4 leading-none mb-4">
              Download the Topgolf Mobile App
            </h1>
            <div className="rounded-sm bg-white-blue dark:bg-gray p-4 lg:flex lg:items-center">
              <div className="flex-1">
                <div>
                  <div className="inline-flex px-4 py-1 rounded-sm text-sm font-shield-black tracking-wide uppercase bg-brand-bright text-white">
                    Limited Time Only!
                  </div>
                </div>
                <div className="mt-2">
                  Get an extra <span className="font-shield-bold">$50 in bonus game play</span> when
                  you load $150 to your Membership via the Topgolf App.**
                </div>
              </div>
            </div>
            <div className="mt-8">
              <p className="">
                The free Topgolf App gives you access to game history, bay reservations and you can
                even control what you watch in your bay.*
              </p>
              {country == 'uk' && (
                <div className="rounded bg-brand-navy dark:bg-gray-dark shadow-md text-white-blue p-4 flex items-center justify-center leading-tight text-sm mb-8 mx-auto max-w-3xl">
                  <i
                    className="fas fa-exclamation-triangle text-accent-yellow text-base mr-4"
                    aria-hidden={true}
                  />
                  <div>
                    <span className="font-shield-bold uppercase">Note:</span> The App is currently
                    not available for Topgolf Glasgow.
                  </div>
                </div>
              )}
              <div className="no-underline flex items-center mx-auto space-x-2">
                <a
                  className="appstore-badge appstore-badge--ios rounded-md h-10 w-auto border-none"
                  href="https://apps.apple.com/us/app/topgolf/id646322524?ls=1"
                  title="App Store"
                  role="button">
                  <span className="sr-only">App Store</span>
                </a>
                <a
                  className="appstore-badge appstore-badge--google rounded-md h-10 w-auto border-none"
                  href="https://play.google.com/store/apps/details?id=com.topgolf.mobile"
                  title="Google Play"
                  role="button">
                  <span className="sr-only">Google Play</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2>Features:</h2>
          <div className="prose dark:prose-dark mt-4 mb-8">
            <ul className="">
              <li>Track your game history and stats</li>
              <li>Make reservations to more easily plan your visit*</li>
              <li>
                Join the waitlist for a bay on the day of your visit{' '}
                <span className="font-shield-bold">before</span> heading our way*
              </li>
              <li>Poll your friends on the best day and time to come play</li>
              <li>Get contact info, hours and amenities for any Topgolf location</li>
              <li>Access our contactless food and beverage menu</li>
              <li>Reload your player card value*</li>
              <li>Check out upcoming events, news and offers</li>
              <li>Change the channel on your bay’s TV with our enhanced remote*</li>
              <li>See how you stack up on the leaderboards</li>
            </ul>
          </div>
          <p className="disclaimer">
            *Full app functionality and venue amenities are not available for our U.K. locations.
            Join the waitlist from home is available at all U.S. venues, excluding Augusta,
            Chattanooga, El Segundo, Las Vegas, and Waco.
          </p>
          <p className="disclaimer mb-0">
            **This promotion is only available in the Topgolf App. Limit of one (1) per Member per
            month. Value added may only be used for game play and must be redeemed at a U.S. Topgolf
            location. Not valid for use on parties, events, memberships, food and beverage, online
            reservations and merchandise. Excludes Swing Suite and Toptracer Range locations. Valid
            for a limited time only, while supplies last.
          </p>
        </div>
      </ContentBlock>
    </Layout>
  );
};

export const getStaticPaths: GetStaticPaths<CountryStaticProps> = async () => {
  return getCountryStaticPaths();
};

export const getStaticProps: GetStaticProps<PageProps, CountryStaticProps> = async ({ params }) => {
  const resource = await buildResource({ id: 121280, country: params.country });
  if (resource.id === 0) {
    return { notFound: true };
  }

  return {
    props: {
      title: resource.pagetitle,
      country: params.country,
      resource,
    },
  };
};

export default MobileApp;
