import { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import { SocialLinks } from '../components/elements/socialLinks';
import { Tracking } from '../components/elements/tracking';
import { Country } from '../services/types';
import { s3 } from '../utils/env';

export const FourOhFour: NextPage = () => {
  const [country, setCountry] = useState<Country>();
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const countryCode = window.location.pathname.split('/')[1];
      if (countryCode === 'us') {
        setCountry('us');
      } else {
        setCountry('uk');
      }
    } else {
      setCountry('uk');
    }
  }, [country, setCountry]);
  return (
    <>
      <Head>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <title>Page Not Found</title>
        <link rel="icon" href="https://topgolf.com/favicon.ico" sizes="48x48" />
        <link rel="icon" href="https://topgolf.com/favicon.svg" sizes="any" type="image/svg+xml" />
        <link rel="apple-touch-icon" href={s3 + 'apple-touch-icon-cpa.png?v=2023'} />
        <link rel="manifest" href="https://topgolf.com/site.webmanifest" />
        <link rel="mask-icon" href={s3 + 'safari-pinned-tab.svg?v=2019.01.02'} color="#050786" />
        <meta name="msapplication-TileColor" content="#050786" />
        <meta name="theme-color" content="#050786" />
      </Head>
      {country && <Tracking country={country} strategy={`afterInteractive`} />}
      <div className="min-h-screen min-w-screen errorpage no-decoration text-center">
        <div className="flex flex-col h-full min-h-3/4-screen lg:min-h-screen justify-center p-8">
          <div className="content my-auto filter drop-shadow-lg text-white">
            <h1 className="font-shield-semibold-condensed text-10 md:text-oversize mt-8 lg:mt-0 mb-0 leading-none">
              404
            </h1>
            <h2 className="font-shield-semibold-condensed text-8xl lg:text-5 lg:-mt-4 uppercase">
              Page not found
            </h2>
            <p className="lg:text-lg my-8 font-shield-bold text-shadow">
              Welp, looks like you missed the target. Use the buttons below to tee it up again.
            </p>
            <nav className="flex items-center justify-center gap-4">
              <Link href="/locations/" legacyBehavior>
                <a className="btn outline white" title="Locations">
                  Locations
                </a>
              </Link>
              <a href="/" className="btn outline white" title="Home">
                Go Home
              </a>
            </nav>
          </div>
          <div className="mx-auto mt-16 mb-4">
            <ul className="w-full flex justify-between flex-wrap space-x-4 lg:space-x-8">
              <SocialLinks iconClass="text-white hover:text-white-blue shadow-xl" />
            </ul>
          </div>
        </div>
      </div>
      <style jsx>{`
        .errorpage {
          background-image: url(${s3 + 'uploads/tg-404-bg-target-01.jpg'});
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
        }
        .errorpage::before {
          background-color: rgba(21, 34, 49, 0.5);
          bottom: 0;
          content: ' ';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
        }
        .errorpage > * {
          position: relative;
          z-index: 2;
        }
      `}</style>
    </>
  );
};

export default FourOhFour;
